<template>
    <v-container grid-list-md text-xs-center>
        <v-layout row wrap>
            <v-flex xs4 v-for="(script, index) in scripts" :key="index">
                <v-card class="pa-3">
                    <v-card-title class="px-0 title">{{script.label}}</v-card-title>
                    <v-card-text>{{script.description}}</v-card-text>
                    <v-btn color="primary" block @click="runScript(script)">Run</v-btn>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "Scripts",
        data: () => ({
            title: "Scripts",
            scripts: {},
        }),
        created () {
            this.$hget('/tools/').then(response=> this.scripts = response.data)
        },
        methods: {
            runScript(script){
                this.$hpost('/tools/exec/' + script.code +'/')
                    .then(this.$notify())
            }
        }
    }
</script>

<style scoped>

</style>
